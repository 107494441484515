import { SentryEnvironment } from '@techniek-team/sentry-web';
import { Environment } from './environment.interface';
import { TtFeatureFlagsEnvironment } from './tt-feature-flags-environment.enum';

export const environment: Environment = {
  production: true,
  debug: false,
  environment: SentryEnvironment.PRODUCTION,
  release: 'v0.0.1',
  scheduler: {
    iri: '/api',
    url: 'https://roostertool.mijnskoleo.nl',
  },
  mercure: {
    hub: 'https://mercure-roostertool.lyceo.nl/.well-known/mercure',
    tokenUrl: 'https://roostertool.mijnskoleo.nl/api/v3/mercure-token',
  },
  perza: {
    iri: '/api',
    url: 'https://perza.lyceo.nl',
  },
  ssoConfig: {
    authority: 'https://login.lyceo.nl/realms/sso',
    clientId: 'scheduler-production',
    baseUrl: 'https://roostertool.mijnskoleo.nl/',
    redirectUri: 'https://alpha.roostertool.lyceo.nl/',
  },
  gitlabFeatureFlags: {
    url: 'https://roostertool.mijnskoleo.nl/api/features',
    environment: TtFeatureFlagsEnvironment.PRODUCTION,
  },
};
